import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import { cacheGetItemData, cacheSetItem } from '../../api/cache'
import { queryRun } from '../../api/query'
import ResultsTable from './results-table'

const C = () => {
  const account = useSelector(state => state.accountReducers.account)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState(cacheGetItemData(`${account.id}-query`) || 'SELECT * FROM `feedback` WHERE audience = \'Student\' ORDER BY date DESC LIMIT 10')
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)

  const runQuery = async () => {
    setLoading(true)
    setError(null)

    const response = await queryRun(query, account.id)
    console.log(response)
    if (response.error) {
      setError(response.error)
    } else {
      setResults(response.results)
    }

    setLoading(false)
  }

  return (
    <Container fluid>
      <Row className="py-4">
        <Col>
          <h1>Query</h1>
        </Col>
      </Row>
      <Row className="py-4">
        <Input
          value={query}
          onChange={e => {
            setQuery(e.target.value)
            cacheSetItem('query', e.target.value)
          }} type="textarea"
        ></Input>
        <Button onClick={() => runQuery()} disabled={loading}>Run query</Button>
      </Row>

      {error &&
        <Row className="py-4 error">
          Error: {error}
        </Row>
      }

      {loading &&
        <Row className="py-4">
          Loading...
        </Row>
      }

      {results.length > 0 &&
        <Row className="py-4">
          <ResultsTable results={results} />
        </Row>
      }

    </Container>
  )
}

export default C
