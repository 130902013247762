import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { cacheGetItemData, cacheSetItem } from '../../api/cache'
import { queryRun } from '../../api/query'
import TrendChart from './trend-chart'
import HomeSection from './home-section'

const query = `SELECT 
date,
CASE WHEN feel = 'Very disappointed' THEN 1 ELSE 0 END AS bad_feel
FROM \`feedback\`
WHERE ORDER BY date ASC`

const getAudienceQuery = (audience) => {
  const where = []

  for (let i = 0; i < audience.length; i++) {
    where.push(`audience = '${audience[i]}'`)
  }

  if (audience.length === 0) {
    where.push('false')
  }

  return query.replace('WHERE', `WHERE ${where.join(' OR ')}`)
}

const chartableData = (data) => {
  const labels = []
  const values = []

  _.forEach(data, row => {
    labels.push((new Date(row.date)).toISOString().slice(0, 7))
    values.push(Math.round(10000 * row.trend) / 100)
  })

  return { labels, values }
}

const calculateTrend = (results) => {
  let lastValue = 0
  const data = []
  for (let i = 0; i < results.length; i++) {
    const row = {
      date: results[i].date.value,
      bad_feel: results[i].bad_feel,
      trend: lastValue + (results[i].bad_feel - lastValue) / 50
    }
    lastValue = row.trend
    data.push(row)
  }

  return data
}

const C = () => {
  const account = useSelector(state => state.accountReducers.account)
  const selectedAudience = useSelector((state) => state.homeControl.audience)
  const [trendResults, setTrendResults] = useState(cacheGetItemData(`trend-results-${selectedAudience.join('-')}`) || false)
  const [loading, setLoading] = useState(!cacheGetItemData(`trend-results-${selectedAudience.join('-')}`))
  const [dataError, setError] = useState(null)
  const [cacheIndex, setCacheIndex] = useState(account.metadata.submissionsCount)

  const run = async () => {
    if (cacheGetItemData(`${account.id}-trend-results-${selectedAudience.join('-')}`)) {
      setTrendResults(cacheGetItemData(`${account.id}-trend-results-${selectedAudience.join('-')}`))
    } else {
      setLoading(true)

      const { results, error } = await queryRun(getAudienceQuery(selectedAudience), account.id)

      if (!error) {
        const data = calculateTrend(results)
        setTrendResults(data)
        setLoading(false)
        setError(false)
        cacheSetItem(`${account.id}-trend-results-${selectedAudience.join('-')}`, data)
      } else {
        console.log(error)
        setError(error)
      }
    }
  }

  useEffect(() => {
    if (selectedAudience.length > 0) {
      run()
    }
  }, [selectedAudience])

  useEffect(() => {
    if (account.metadata.submissionsCount !== cacheIndex) {
      setCacheIndex(account.metadata.submissionsCount)
      setLoading(true)
      cacheSetItem(`${account.id}-trend-results-${selectedAudience.join('-')}`, false)
      run()
    }
  }, [cacheIndex, account])

  return (<HomeSection name="trend">
    <h2>Trend</h2>
    {dataError && <p>{dataError}</p>}
    {!dataError && loading && <p className="loading">Loading...</p>}
    {!dataError && !loading && trendResults.length > 0 && <TrendChart data={chartableData(trendResults)}></TrendChart>}
    {!dataError && !loading && trendResults.length === 0 && <p className="loading">No data</p>}
  </HomeSection>)
}

export default C
