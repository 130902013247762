import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Col, Container, Row } from 'reactstrap'
import { whatToBuild } from '../../api/query'
import { cacheGetItemData, cacheSetItem } from '../../api/cache'
import { useDispatch, useSelector } from 'react-redux'
import RecentSummaries from './recent-summaries'
import Loader from '../../components/Loader'
import { defaultSettings } from './default-settings'
import { deepDiveSettingGet, deepDiveSettingSubscribeAll } from '../../api/accounts/deep-dive-settings'
import { useNavigate } from 'react-router'
import { setAudienceAll } from '../../core/reducers/home-control'
import { accountUpdate } from '../../api/accounts'
import { audienceAllGet } from '../../api/audience'
import './index.scss'

const debug = true
const showAdvanced = false

const durationOptions = [
  { label: 'all', value: false },
  { label: '7 Days', value: '7 DAY' },
  { label: '30 Days', value: '30 DAY' },
  { label: '3 Months', value: '3 MONTH' },
  { label: '6 Months', value: '6 MONTH' },
  { label: '12 Months', value: '1 YEAR' }
]

const DeepDive = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountReducers.account)
  const audienceAll = useSelector((state) => state.homeControl.audienceAll)
  const [loading, setLoading] = useState(false)
  const [duration, setDuration] = useState(cacheGetItemData(`${account.id}-wtb-duration`) || false)
  const [audience, setAudience] = useState(cacheGetItemData(`${account.id}-wtb-audience`) || false)
  const [error, setError] = useState(null)
  const [opened, setOpened] = useState(false)
  const [resultsCount, setResultsCount] = useState(false)
  const [settingSelected, setSettingSelected] = useState(defaultSettings)
  const [settings, setSettings] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (account.metadata.settingSelected && account.metadata.settingSelected !== 'default') {
      deepDiveSettingGet(account.id, account.metadata.settingSelected).then((data) => {
        setSettingSelected(data || defaultSettings)
      })
    }
  }, [account])

  useEffect(() => {
    return deepDiveSettingSubscribeAll(account.id, (data) => {
      setSettings(data)
    })
  }, [])

  useEffect(() => {
    if (audienceAll.length === 0) {
      audienceAllGet().then(results => {
        dispatch(setAudienceAll(_.map(results, 'audience')))
      })
    }
    console.log({ audienceAll })
  }, [audienceAll])

  const setDurationHandle = (value) => {
    if (value === 'false') {
      value = false
    }

    setDuration(value)
    cacheSetItem(`${account.id}-wtb-duration`, value)
  }

  const setAudienceHandle = (value) => {
    setAudience(value)
    cacheSetItem(`${account.id}-wtb-audience`, value)
  }

  const generate = async () => {
    setLoading(true)
    setError(null)

    const inputs = {
      audience,
      duration,
      action: settingSelected.metadata.action,
      feel: settingSelected.metadata.feel,
      maxTokens: settingSelected.metadata.maxTokens,
      model: settingSelected.metadata.model,
      settingId: settingSelected.id,
      systemMessage: settingSelected.metadata.systemMessage,
      temperature: settingSelected.metadata.temperature
    }

    if (debug) {
      console.log(inputs)
    }

    const response = await whatToBuild({
      accountId: account.id,
      ...inputs
    })

    if (response.error) {
      cacheSetItem(`${account.id}-query`, response.query)
      setResultsCount(response.resultsCount)

      setError(response.error)
    } else {
      setOpened(response.summaryId)
    }

    setLoading(false)
  }

  const handleChangeSetting = async (value) => {
    await accountUpdate(account.id, { settingSelected: value })
  }

  const changeSettings = (inputs) => {
    setDurationHandle(inputs.duration)
    setAudienceHandle(inputs.audience)
    handleChangeSetting(inputs.settingId)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Deep dive</h1>
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          <h4>Action <Button
                        color="primary"
                        className="action-edit-button"
                        onClick={() => navigate(`/dashboard/deep-dive/settings/${account.metadata.settingSelected || 'default'}`)}
                      >{account.metadata.settingSelected === 'default' ? 'Create' : 'Edit' }</Button></h4>

          <select
            className='input-container__input action-selection'
            onChange={e => handleChangeSetting(e.target.value)}
            value={account.metadata.settingSelected || 'default'}
          >
            <option value="default" className="default"><p>My action</p></option>
            {settings.map((s) => (
              <option
                value={s.id}
                key={s.id}

              >{s.metadata.name}</option>
            ))}
          </select>
        </Col>

        <Col>
          <h4>Duration</h4>
          <select
            className='input-container__input'
            onChange={e => setDurationHandle(e.target.value)}
            value={duration}
          >
            {durationOptions.map((option) => (
              <option
                value={option.value}
                key={option.value}
              >{option.label}</option>
            ))}
          </select>
        </Col>
        <Col>
          <h4>Audience</h4>
          <select
            className='input-container__input'
            onChange={e => setAudienceHandle(e.target.value)}
            value={audience}
          >
            {['all', ...audienceAll].map((option) => (
              <option
                value={option}
                key={option}
              >{option}</option>
            ))}
          </select>
        </Col>

        <Col>
          <h4>&nbsp;</h4>
          <Button onClick={() => generate()} color="primary" disabled={loading}>{loading ? <Loader /> : 'Generate' }</Button>
        </Col>

      </Row>

      {error &&
        <Row className="py-4 error">
          <Col>
            {error}
          </Col>
        </Row>
      }

      {loading &&
        <Row className="py-4">
          <Col>
            Loading...
          </Col>
        </Row>
      }

      {!loading && resultsCount !== false &&
        <Row className="py-4">
          <Col>
            Results count: {resultsCount}
          </Col>
        </Row>
      }

      <RecentSummaries
        changeSettings={changeSettings}
        className="py-4"
        showAdvanced={showAdvanced}
        setOpened={setOpened}
        opened={opened}
        settings={settings}
      />
    </Container>
  )
}

export default DeepDive
